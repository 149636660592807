<template>
<div class="admin-container" v-loading.fullscreen.lock="loading">
    <div class="admin-container-header">
        <div class="flex justify-between">
            <h1> Static Pages</h1> 
        </div>
    </div>
    <div class="admin-table">
        <table class="admin-table-table" >
            <tr>
            <th>Ім'я</th>
            <td style="width:100px">Дії</td>
            </tr>
            <tr>
                <td>Головна</td>
                <td><button @click="$router.push(`/admin/statpages/edit/home`)" slot="reference">Редагувати</button></td>
            </tr>
            <tr>
                <td>Оформити кредит</td>
                <td><button @click="$router.push(`/admin/statpages/edit/getcredit`)" slot="reference">Редагувати</button></td>
            </tr>
            <tr>
                <td>Акції</td>
                <td><button @click="$router.push(`/admin/statpages/edit/stock`)" slot="reference">Редагувати</button></td>
            </tr>
            <tr>
                <td>Контакти</td>
                <td><button @click="$router.push(`/admin/statpages/edit/contact`)" slot="reference">Редагувати</button></td>
            </tr>
            <tr>
                <td>Faq</td>
                <td><button @click="$router.push(`/admin/statpages/edit/faq`)" slot="reference">Редагувати</button></td>
            </tr>
             <tr>
                <td>Про компанію</td>
                <td><button @click="$router.push(`/admin/statpages/edit/procompany`)" slot="reference">Редагувати</button></td>
            </tr>
            <tr>
                <td>Лізинг</td>
                <td><button @click="$router.push(`/admin/statpages/edit/leasing`)" slot="reference">Редагувати</button></td>
            </tr>
            <tr>
                <td>Переваги</td>
                <td><button @click="$router.push(`/admin/statpages/edit/advantages`)" slot="reference">Редагувати</button></td>
            </tr>
            <tr>
                <td>Калькулятор</td>
                <td><button @click="$router.push(`/admin/statpages/edit/calculator`)" slot="reference">Редагувати</button></td>
            </tr>
            <tr>
                <td>Footer</td>
                <td><button @click="$router.push(`/admin/statpages/edit/footer`)" slot="reference">Редагувати</button></td>
            </tr>
            <tr>
                <td>Nav</td>
                <td><button @click="$router.push(`/admin/statpages/edit/nav`)" slot="reference">Редагувати</button></td>
            </tr>
        </table>
    </div>
     
</div>
</template>

<script>
export default {
    data() {
        return {
           current_page: 1,
            page_count: 1,
            page_size: 10,
            loading: false,
            search:'',
            Pages: [],
        }

    }, 
    methods: {
        Get() {
            this.loading=true
            this.axios.get('/admin/statpage')
                .then(res => {
                    this.page_count = parseInt(res.data.data.total / this.page_size) 
                    if (res.data.data.total % this.page_size > 0) {
                        this.page_count = this.page_count + 1
                    } 
                    var data=[]
                    for(let i=0; i<res.data.data.length; i++){  
                        data.push(res.data.data.data[i]) 
                        if(typeof data[i].data === 'string'){  
                            data[i].data=JSON.parse(data[i].data)
                        }
                        
                    }
                    this.Pages = data

                    
                })
                .finally(()=>{
                    this.loading=false
                })
        },
        paginateSize() {
            console.log("AAA")
        },
       
    },
    computed:{
          Pager() {
            return this.$store.state.Home
        }
    },
    mounted() {
        this.Get() 
    }
}
</script>

<style lang="scss">
.admin-container {
    background: var(--white-bg);
    min-height: 100%;
    width: 100%;

}

.admin-container-header {
    padding: 20px;
}

.admin-table {
    width: 100%;
}
.admin-table-table{
    width: 100%;
    border-collapse: collapse;
    
    th{
        text-align: left;
        padding: 10px;
        border-collapse: collapse;
        font-family: Stem-Medium;
    }
    td{
        padding: 10px;
        border-collapse: collapse;
    }
    tr{
        border-bottom: 1px solid #EBEEF5;
        border-collapse: collapse;
        font-family: Stem-Regular;
    }
    button{ 
        border: none;
        font-family: Stem-Medium;
        padding: 8px 16px;
        color: var(--white-color);
        border: none;
        background: #44405C;
        cursor: pointer;
        outline: none;
        border-radius: 4px;

    }
}

.admin-poginations {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: flex-end;
}
</style>
